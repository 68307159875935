import { Controller } from 'stimulus'

export default class extends Controller {
  highlight (e) {
    const highlighted = e.target.closest('.highlightable')

    document.querySelectorAll('.highlightable').forEach(element => {
      if (element != highlighted) {
        element.classList.add('dimmed')
      }
    })

    // document.body.classList.add('dimmed')
  }

  resetHighlight () {
    // document.body.classList.remove('dimmed')

    document.querySelectorAll('.highlightable').forEach(element => {
      element.classList.remove('dimmed')
    })
  }

  zoomin (e) {
    e.target.closest('.highlightable').classList.add('zoomed')
  }
}
