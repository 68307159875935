import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const articleContentBackgroundColor = document.querySelector('r-grid#article-content').style.backgroundColor

    document.querySelector('body').style.backgroundColor = articleContentBackgroundColor
  }

  disconnect() {
    document.querySelector('body').style.backgroundColor = ''
  }
}
