import { Controller } from 'stimulus'

export default class extends Controller {
  switchNavMenu() {
    this.closeSearchBox()

    if (document.getElementsByTagName('nav-menu')[0].classList.contains('open')) {
      this.closeNavMenu()
    } else {
      this.openNavMenu()
    }
  }

  switchSearchBox() {
    this.closeNavMenu()

    if (document.getElementsByTagName('search-box')[0].style.display !== 'flex') {
      this.openSearchBox()
    } else {
      this.closeSearchBox()
    }
  }

  openNavMenu () {
    document.getElementsByTagName('nav-menu')[0].classList.add('open')
  }

  closeNavMenu () {
    document.getElementsByTagName('nav-menu')[0].classList.remove('open')
  }

  openSearchBox () {
    document.getElementsByTagName('content')[0].style.display = 'none'
    document.getElementsByTagName('search-box')[0].style.display = 'flex'
  }

  closeSearchBox () {
    document.getElementsByTagName('content')[0].style.display = 'block'
    document.getElementsByTagName('search-box')[0].style.display = 'none'
  }
}
