import '@exampledev/new.css'
import '../styles/index.scss'
import Swup from 'swup'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'
import SwupFadeTheme from '@swup/fade-theme'
import SwupPreloadPlugin from '@swup/preload-plugin'
import SwupProgressPlugin from '@swup/progress-plugin'
import SwupGaPlugin from '@swup/ga-plugin'
import SwupGtmPlugin from '@swup/gtm-plugin'
import 'bridgetown-quick-search'
import LazyLoad from 'vanilla-lazyload'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

window.swup = new Swup({
  linkSelector:
    'a[href^="' +
    window.location.origin +
    '"]:not([data-no-swup]), a[href^="?"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])',
  plugins: [
    new SwupBodyClassPlugin(),
    new SwupScrollPlugin({
      animateScroll: false,
    }),
    new SwupFadeTheme(),
    // new SwupPreloadPlugin(),
    new SwupProgressPlugin(),
    new SwupGtmPlugin(),
    new SwupGaPlugin(),
  ],
})

window.lazyload = new LazyLoad({
  // Your custom settings go here
})

window.swup.on('contentReplaced', () => {
  window.lazyload.update()

  // Enforce fly to be cleaned up
  document.querySelectorAll('.bug').forEach((el) => {
    el.remove()
  })
})

const application = Application.start()
const context = require.context('./controllers', true, /.js$/)
application.load(definitionsFromContext(context))

// Handles loading the events for <model-viewer>'s slotted progress bar
const onProgress = (event) => {
  const progressBar = event.target.querySelector('.progress-bar')
  const updatingBar = event.target.querySelector('.update-bar')
  updatingBar.style.width = `${event.detail.totalProgress * 100}%`
  if (event.detail.totalProgress === 1) {
    progressBar.classList.add('hide')
  } else {
    progressBar.classList.remove('hide')
    if (event.detail.totalProgress === 0) {
      event.target.querySelector('.center-pre-prompt').classList.add('hide')
    }
  }
}
document.querySelector('model-viewer').addEventListener('progress', onProgress)
