import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.initScroll()
  }

  scaleNav() {
    let currentScrollPos = window.pageYOffset
    let currentScrollDirDown = (this.prevScrollpos < currentScrollPos) ? true : false

    if (currentScrollPos < 30) {
      document.querySelector('nav').classList.remove('blurred')
      this.prevScrollDirDown = false
    } else if (currentScrollDirDown && !this.prevScrollDirDown) {
      document.querySelector('nav').classList.add('blurred')

      this.prevScrollDirDown = currentScrollDirDown
    }

    this.prevScrollpos = currentScrollPos
  }

  initScroll() {
    this.prevScrollpos = window.pageYOffset
    this.prevScrollDirDown = false
  }

  onScroll() {
    window.requestAnimationFrame(this.scaleNav.bind(this))
  }
}
