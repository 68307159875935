import Player from './../components/Player.svelte'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'player'
  ]

  static values = {
    playlistUrl: String
  }

  connect() {
    new Player({
      target: this.playerTarget,
      props: {
        playlistUrl: this.playlistUrlValue
    }
    })
  }
}
