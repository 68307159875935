import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    document.querySelectorAll('article-body r-grid img').forEach(i => {
      if (!i.hasAttribute('captionized')) {
        i.setAttribute('captionized', 'true')
        
        let id = Math.floor(Math.random()*16777215).toString(16)
        
        i.outerHTML = `
          <figure>
            <a data-no-swup href="#img-${id}">
              ${i.outerHTML}
            </a>
            
            <a data-no-swup href="#_" class="overlay" id="img-${id}">
              ${i.outerHTML}
            </a>
            
            <figcaption>
              ${i.title}
            </figcaption>
          </figure>
        `
      }
    })
  }
}

// Requires following CSS:
//
// .overlay {
//   position: fixed;
//   z-index: 1000;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0,0,0,1);
//   display: flex;
//   align-items: center;
//   text-align: center;
//   visibility: hidden;

//   img {
//     width: auto;
//     height: auto;
//   }

//   &:target {
//     visibility: visible;
//     outline: none;
//     cursor: default;
//   }
// }
