import { Controller } from 'stimulus'

export default class extends Controller {
	connect() {
    document.addEventListener('mousemove', this.setCursor)
    document.addEventListener('touchmove', this.setCursor)
  }

	setCursor(e){
		let x = e.clientX || e.touches[0].clientX
		let y = e.clientY || e.touches[0].clientY
	      
		document.documentElement.style.setProperty('--cursorX', x + 'px')
		document.documentElement.style.setProperty('--cursorY', y + 'px')
  }
}
