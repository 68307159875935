import { Controller } from 'stimulus'
import fitty from 'fitty'

export default class extends Controller {
  static values = {
    selector: String,
    maxSize: Number,
  }

  connect() {
    fitty(this.selectorValue, {
      maxSize: this.maxSizeValue,
      minSize: 12,
      multiLine: true,
    })
  }
}
