import { Controller } from 'stimulus'

export default class extends Controller {
  switchCloseIcon(e) {
    if (e.target.src.includes('close.svg')) {
      e.target.src = e.target.src.replace('close.svg', e.target.dataset.ogIcon)
    } else {
      e.target.src = e.target.src.replace(e.target.dataset.ogIcon, 'close.svg')
    }
  }
}
