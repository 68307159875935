<script>
  import { tweened } from 'svelte/motion'
  import { linear } from 'svelte/easing'
  import { blur } from 'svelte/transition'
  import { onMount } from 'svelte'

  export let playlistUrl

  let playerUrl = `https://w.soundcloud.com/player/?url=//${
    playlistUrl.split('https://')[1]
  }`

  $: player = null
  $: sounds = []
  $: currentSoundDuration = null
  $: currentSoundId = null
  $: currentSoundUrl = null
  $: currentPosition = null
  $: currentCoverUrl = null
  $: playerHidden = true
  $: playerPaused = true

  onMount(async () => {
    player = window.SC.Widget('soundcloud_widget')

    player.bind(window.SC.Widget.Events.READY, () => {
      playerHidden = false

      player.getSounds((s) => {
        sounds = s
        currentCoverUrl = sounds[0].artwork_url
      })
    })

    player.bind(window.SC.Widget.Events.PLAY, () => {
      playerPaused = false

      player.getCurrentSound((currentSound) => {
        ;(currentSoundId = currentSound.id),
          (currentSoundDuration = currentSound.duration),
          (currentCoverUrl = currentSound.artwork_url),
          (currentSoundUrl = currentSound.permalink_url)
      })
    })

    player.bind(window.SC.Widget.Events.PAUSE, () => {
      playerPaused = true
    })

    player.bind(window.SC.Widget.Events.PLAY_PROGRESS, (position) => {
      currentPosition = position.relativePosition
      progress.set(currentPosition)

      let timecodeML =
        currentPosition * document.getElementById('player-progress').clientWidth
      timecodeMarginLeft.set(timecodeML)

      if (timecodeML > 150 && timecodeML < 245) {
        timecodeMarginTop.set(5)
      } else {
        timecodeMarginTop.set(0)
      }
    })
  })

  function pause() {
    player.pause()
  }

  function play() {
    player.play()
  }

  function next() {
    player.next()
  }

  function prev() {
    player.prev()
  }

  function skip(e) {
    player.skip(e.target.dataset.scIndex)
  }

  function sc() {
    window.open(currentSoundUrl, '_blank')
  }

  function seek(e) {
    let bounds = this.getBoundingClientRect()
    let max = bounds.width
    let pos = e.pageX - bounds.left
    let positionRelative = pos / max

    player.seekTo(currentSoundDuration * positionRelative)
  }

  const progress = tweened(0, {
    duration: 50,
    easing: linear,
  })

  const timecodeMarginLeft = tweened(0, {
    duration: 50,
    easing: linear,
  })

  const timecodeMarginTop = tweened(0, {
    duration: 50,
    easing: linear,
  })
</script>

<div class="player">
  <iframe
    id="soundcloud_widget"
    src={playerUrl}
    title="SoundCloud"
    width="100px"
    height="100px"
    frameBorder="no"
  />

  {#if !playerHidden}
    <img
      class="player-body player-body-large"
      src="/images/player.png"
      transition:blur={{ duration: 125, easing: linear, amount: 10 }}
      alt="player-body"
    />
    <img
      class="player-body player-body-small"
      src="/images/player-small.png"
      transition:blur={{ duration: 125, easing: linear, amount: 10 }}
      alt="player-body"
    />

    {#if currentCoverUrl}
      <img
        class="player-cover"
        src={currentCoverUrl}
        transition:blur={{
          duration: 250,
          delay: 250,
          easing: linear,
          amount: 10,
        }}
        alt="cover"
      />
    {/if}

    {#if sounds.length > 0}
      <ol class="player-list">
        {#each sounds as { id, title }, i}
          <li
            class="player-list-track {currentSoundId === id ? 'current' : ''}"
            key={id}
            data-sc-index={i}
            on:click={skip}
            transition:blur={{
              duration: 250,
              delay: 50 * i,
              easing: linear,
              amount: 10,
            }}
            role="button"
          >
            {title}
          </li>
        {/each}
      </ol>
    {/if}

    <img
      class="player-button-pause"
      class:transparent={!playerPaused}
      on:click={pause}
      src="/images/pause.gif"
      alt="pause-button"
      role="button"
    />
    <img
      class="player-button-play"
      class:transparent={playerPaused}
      on:click={play}
      src="/images/play.gif"
      alt="play-button"
      role="button"
    />

    <button class="player-button-next" on:click={next}>Next</button>
    <button class="player-button-prev" on:click={prev}>Prev</button>

    {#if currentSoundUrl}
      <button class="player-button-sc" on:click={sc}>SoundCloud</button>
    {/if}

    <progress
      id="player-progress"
      class="player-progress"
      value={$progress}
      on:click={seek}
    />
    <img
      class="player-timecode"
      style="margin-left: {$timecodeMarginLeft}px; margin-top: {$timecodeMarginTop}px;"
      src="/images/timecode.png"
      alt="player-timecode"
    />
  {/if}
</div>

<style lang="scss">
  $breakpoint-sm: 1367px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    border: 0px solid;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--red-color);
    border-radius: 5px;
  }

  iframe {
    position: fixed;
    top: 100px;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
  }

  .transparent {
    opacity: 0;
  }

  .player {
    min-height: 1088px;
  }

  .player-body {
    position: absolute;
    height: 1500px;
    width: 1500px;
    max-width: initial;
    margin-top: -306px;
    margin-left: -30px;
    z-index: 2;
    pointer-events: none;

    @media (max-width: $breakpoint-sm) {
      margin-top: -66px;
      margin-left: -354px;
    }
  }

  .player-body-large {
    display: block;

    @media (max-width: $breakpoint-sm) {
      display: none;
    }
  }

  .player-body-small {
    display: none;

    @media (max-width: $breakpoint-sm) {
      display: block;
    }
  }

  .player-list {
    position: absolute;
    top: 223px;
    left: 1012px;
    z-index: 3;
    height: 184px;
    overflow-y: scroll;
    color: var(--red-color);
    font-weight: bold;
    -webkit-font-smoothing: none;
    font-size: 14px;

    @media (max-width: $breakpoint-sm) {
      top: 941px;
      left: 14px;
      height: 400px;
    }
  }

  .player-list-track {
    list-style-type: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 0 3px;
    margin-right: 5px;
  }

  .player-list-track:hover {
    box-shadow: 0 0 0 1px var(--red-color);
  }

  .player-list-track.current {
    box-shadow: 0 0 0 1px var(--red-color);
    background-color: var(--red-color);
    color: white;
  }

  .player-cover {
    border-radius: 50%;
    position: absolute;
    top: 208px;
    left: 96px;
    width: 625px;
    height: auto;
    z-index: 1;
    filter: blur(4px);

    @media (max-width: $breakpoint-sm) {
      top: 20px;
      left: 12px;
      width: 605px;
    }
  }

  .player-button-pause {
    position: absolute;
    top: 305px;
    left: 592px;
    z-index: 3;
    height: 75px;
    width: auto;
    cursor: pointer;

    @media (max-width: $breakpoint-sm) {
      top: 601px;
      left: 202px;
    }
  }

  .player-button-play {
    position: absolute;
    top: 296px;
    left: 669px;
    z-index: 3;
    height: 91px;
    width: auto;
    cursor: pointer;

    @media (max-width: $breakpoint-sm) {
      top: 601px;
      left: 202px;
    }
  }

  .player-button-next {
    position: absolute;
    top: 529px;
    left: 893px;
    z-index: 3;
    background-color: transparent;
    opacity: 0;
    height: 41px;
    width: 102px;

    @media (max-width: $breakpoint-sm) {
      top: 601px;
      left: 202px;
    }
  }

  .player-button-prev {
    position: absolute;
    top: 530px;
    left: 710px;
    z-index: 3;
    background-color: transparent;
    opacity: 0;
    height: 41px;
    width: 102px;

    @media (max-width: $breakpoint-sm) {
      top: 601px;
      left: 202px;
    }
  }

  .player-button-sc {
    position: absolute;
    top: 728px;
    left: 860px;
    z-index: 3;
    background-color: transparent;
    opacity: 0;
    height: 43px;
    width: 79px;

    @media (max-width: $breakpoint-sm) {
      top: 601px;
      left: 202px;
    }
  }

  .player-progress {
    position: absolute;
    top: 464px;
    left: 689px;
    z-index: 3;
    width: 311px;
    height: 42px;
    accent-color: gold;
    cursor: pointer;
    opacity: 0;

    @media (max-width: $breakpoint-sm) {
      top: 601px;
      left: 202px;
    }
  }

  .player-timecode {
    position: absolute;
    top: 467px;
    left: 671px;
    z-index: 2;
    width: 46px;

    @media (max-width: $breakpoint-sm) {
      top: 601px;
      left: 202px;
    }
  }
</style>
