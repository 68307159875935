import { Controller } from 'stimulus'
import Autolinker from 'autolinker'

export default class extends Controller {
  connect() {
    let article = document.querySelector('article-body')
    article.innerHTML = Autolinker.link(article.innerHTML, {
      truncate: { length: 20, location: 'end' }
    })
  }
}
