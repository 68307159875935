import { Controller } from 'stimulus'
import lunr from 'lunr'

// Based on:
// https://github.com/bridgetownrb/bridgetown-quick-search/blob/master/frontend/javascript/search_engine.js

export default class extends Controller {
  connect () {
    this.generateIndex()
  }

  async generateIndex () {
    const index = await fetch('/bridgetown_quick_search/index.json')
    const indexData = await index.json()
    this.indexData = indexData

    this.index = lunr(function () {
      this.ref('id')
      this.field('id')
      this.field('title', { boost: 10 })
      this.field('tags')
      this.field('url')

      indexData.forEach(item => {
        if (item.content) {
          this.add(item)
        }
      })
    })
  }

  search (e) {
    this.clearResults()

    if (e.target.value.length < 3) {
      return
    }

    this.query = e.target.value

    if (this.index) {
      this.index.search(this.query).map(result => {
        const item = this.indexData.find(item => item.id == result.ref)
        this.appendResult(item)
      })
    } else {
      throw new Error("Search index hasn't yet loaded. Run the generateIndex function")
    }
  }

  clearResults () {
    document.querySelectorAll('search-results a').forEach(el => el.remove())
  }

  appendResult (result) {
    const a = document.createElement('a')
    a.href = result.url
    a.innerHTML = this.highlightResult(result.title)

    document.getElementsByTagName('search-results')[0].appendChild(a)
  }

  highlightResult (text, length) {
    if (length == null) { length = 300 }
    const padding = length / 2
    let output

    if (length) {
      const textToSearch = text.toLowerCase()
      const wordLocations = this.query.toLowerCase().split(' ').map(word => {
        return textToSearch.indexOf(word)
      }).filter(location => location != -1).sort((a, b) => { return a - b })

      if (wordLocations[1]) {
        length = Math.min(wordLocations[1] - wordLocations[0], length)
      }

      output = text.substr(Math.max(0, wordLocations[0] - padding), length + padding)
    } else {
      output = text
    }

    if (!text.startsWith(output)) {
      output = '…' + output
    }
    if (!text.endsWith(output)) {
      output = output + '…'
    }

    this.query.toLowerCase().split(' ').forEach(word => {
      if (word != '') {
        output = output.replace(
          new RegExp(`(${word.replace(/[\.\*\+\(\)]/g, '')})`, 'ig'),
          '<strong>$1</strong>'
        )
      } else {
        output = output.replace(
          new RegExp(`(${word.replace(/[\.\*\+\(\)]/g, '')})`, 'ig'),
          '<non-strong>$1</non-strong>'
        )
      }
    })

    return output
  }
}
