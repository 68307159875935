import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.showSwitcher()
  }

  showSwitcher() {
    if (!window.location.href.includes('l=true')) {
      document.getElementsByTagName('locale-select')[0].style.display = 'flex'
    }
  }
}
